<template>
  <div class="flightbooking_form_middelitem">
    <h4>{{ $t("sabre.booking.baggage-box.adding-suitcase-for", {travel: personName}) }}</h4>
    <div class="d-flex justify-content-between align-items-center dcolumn">
      <div class="d-flex align-items-center dcolumn">

        <selection-count v-model="outCount" :title="$t('sabre.booking.baggage-box.flight-to', {dest: getCityName(destCode)})"
        :range="outMinMaxPiece"/>

        <selection-count v-model="returnCount" :title="$t('sabre.booking.baggage-box.flight-to', {dest: getCityName(returnCode)})"
        :range="returnMinMaxPiece" v-if="!isOneWay"/>

      </div>
      <div class="suitcases_box">
        <h4>{{ outCount + returnCount }} {{$t("sabre.booking.baggage-box.suitcases")}}* ${{ averagePrice }} <strong>${{ totalPrice }} </strong></h4>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'baggage-one-item',
  props: {
    baggageInfoData: {
      type: Array,
      default: () => [],
    },
    personData: {
      type: Object,
      default: null,
    },
  },
  components: {
    SelectionCount: () => import('./selectionCount'),
  },
  computed: {
    ...mapGetters({
      orderedFlight: 'GET_SABRE_ORDERED_FLIGHT',
      condition: 'GET_SABRE_ORDERED_CONDITION',
      directionList: 'GET_SABRE_FLIGHT_DIRECTION_LIST',
      destinationList: 'GET_DESTINATION_IMAGES',
      bagChargeDesc: 'GET_SABRE_BOOKING_BAGGAGE_DESC',
    }),
    personName() {
      const { personData } = this;
      return `${personData.firstName} ${personData.lastName}`;
    },
    destinations() {
      const { destinationList } = this;
      // return destinationList?.filter((dest) => dest.forSabreFlight) || [];
      return destinationList?.filter((dest) => dest.name) || [];
    },
    isOneWay() {
      const { condition, directionList } = this;
      return condition.flightDirection === directionList[1];
    },
    destCode() {
      const { condition } = this;
      return condition.originDestinationInformation[0].destinationCodes[0];
    },
    returnCode() {
      const { condition } = this;
      return condition.originDestinationInformation[0].originCodes[0];
    },

    outBaggageInfo() {
      const { baggageInfoData } = this;
      return baggageInfoData.filter((info) => info.segments.includes(0));
    },
    outMinMaxPiece() {
      const { outBaggageInfo } = this;
      if (outBaggageInfo.length > 0) {
        if (this.checkBagAvailable(outBaggageInfo)) {
          return { min: 0, max: Infinity };
        }
      }

      return { min: 0, max: 0 };
    },
    outPrice() {
      const { outCount, outBaggageInfo, bagChargeDesc } = this;
      const outDescArr = this.getDescArray(bagChargeDesc, outBaggageInfo);
      if (!outDescArr.length) return 0;

      const appropriateDesc = outDescArr?.find((desc) => outCount >= desc.firstPiece && outCount <= desc.lastPiece) || outDescArr[0];
      return appropriateDesc.equivalentAmount;
    },
    returnBaggageInfo() {
      const { baggageInfoData } = this;
      return baggageInfoData.filter((info) => !info.segments.includes(0));
    },
    returnMinMaxPiece() {
      const { returnBaggageInfo } = this;
      if (returnBaggageInfo.length > 0) {
        if (this.checkBagAvailable(returnBaggageInfo)) {
          return { min: 0, max: Infinity };
        }
      }

      return { min: 0, max: 0 };
    },
    returnPrice() {
      const { returnCount, returnBaggageInfo, bagChargeDesc } = this;
      const returnDescArr = this.getDescArray(bagChargeDesc, returnBaggageInfo);
      if (!returnDescArr.length) return 0;

      const appropriateDesc = returnDescArr?.find((desc) => returnCount >= desc.firstPiece && returnCount <= desc.lastPiece) || returnDescArr[0];
      return appropriateDesc.equivalentAmount;
    },
  },
  watch: {
    baggageInfoData() {
      const { outMinMaxPiece, returnMinMaxPiece } = this;
      if (outMinMaxPiece || returnMinMaxPiece) {
        if (this.outCount > outMinMaxPiece.max) this.outCount = outMinMaxPiece.max;
        if (this.outCount < outMinMaxPiece.min) this.outCount = outMinMaxPiece.min;
        if (this.returnCount > returnMinMaxPiece.max) this.returnCount = returnMinMaxPiece.max;
        if (this.returnCount < returnMinMaxPiece.min) this.returnCount = returnMinMaxPiece.min;
      }

      this.update();
    },
    outCount() {
      this.update();
    },
    returnCount() {
      this.update();
    },
  },
  data() {
    return {
      outCount: 0,
      returnCount: 0,
      totalPrice: 0,
      averagePrice: 0,
    };
  },
  mounted() {
    this.update();
  },
  methods: {
    getCityName(code) {
      const { destinations } = this;
      return destinations.find((d) => d.code === code)?.name_Loc?.content || '';
    },
    getDescArray(descArr, info) {
      return descArr.filter((desc) => desc.noChargeNotAvailable !== 'NotAvailable' && info.findIndex((inf) => inf.refId === desc.id) > -1);
    },
    getPieceArray(descArr, info, fieldName) {
      const arr = this.getDescArray(descArr, info);
      return (arr.length) ? arr.map((desc) => {
        const v = desc[fieldName];
        return (v === null) ? Infinity : v;
      }) : null;
    },
    checkBagAvailable(info) {
      const { bagChargeDesc } = this;
      const arr = this.getDescArray(bagChargeDesc, info);

      return (arr.length > 0);
    },
    update() {
      const { personData, outPrice, outCount, returnPrice, returnCount } = this;
      this.totalPrice = Math.round(outPrice * outCount + returnPrice * returnCount);
      this.averagePrice = (outCount + returnCount > 0) ? Math.round(this.totalPrice / (outCount + returnCount)) : 0;
      this.$emit('updateData', {
        passengerRefId: personData.id,
        isOneWay: this.isOneWay,
        outCount,
        outPrice: outPrice * outCount,
        returnCount: this.isOneWay ? null : returnCount,
        returnPrice: returnPrice * returnCount,
        averagePrice: this.averagePrice,
        totalPrice: this.totalPrice,
      });
    },
  },
};
</script>
